import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/user/userSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Typography } from "@mui/material";
import HeightSpacer from "../common/HeightSpacer";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const { token, allUsers } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getAllUsers(token));
    }
  }, [dispatch, token]);

  const renderImage = useCallback(({ name, imageUrl }) => {
    return <Avatar alt={name} src={imageUrl} />;
  }, []);
  return (
    <>
      {allUsers && allUsers?.length > 0 && (
        <>
          <Typography variant={"h6"}>Users: {allUsers?.length}</Typography>
          <HeightSpacer height={16} />
          <div style={{ height: 680, width: "100%" }}>
            <DataGrid
              rows={allUsers}
              getRowId={(row) => row?.email}
              columns={[
                {
                  field: "imageUrl",
                  headerName: "image",
                  width: 50,
                  renderCell: (params) => renderImage(params?.row),
                },
                { field: "name", headerName: "Name", width: 150 },
                { field: "email", headerName: "email", width: 300 },
              ]}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              // pageSizeOptions={[10, 20]}
              slots={{ toolbar: GridToolbar }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
