import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import devices from "../../assets/gif/devices.gif";
import unlimited from "../../assets/gif/unlimited.gif";
import network from "../../assets/gif/network.gif";
import list from "../../assets/gif/list.gif";
import exportGIF from "../../assets/gif/export.gif";
import translate from "../../assets/gif/translate.gif";

import { SECTIONS } from "../../utils/constants";
import FeatureButton from "../common/FeatureButton";

const FeaturesList = () => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      md={6}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Grid item xs={6}>
        <FeatureButton
          src={unlimited}
          text={t("sections.unlimitedLists.title")}
          section={`#${SECTIONS.UNLIMITED}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={network}
          text={t("sections.shareLists.title")}
          section={`#${SECTIONS.SHARE}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={list}
          text={t("sections.customizeItems.title")}
          section={`#${SECTIONS.ITEMS}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={exportGIF}
          text={t("sections.exportText.title")}
          section={`#${SECTIONS.EXPORT}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={devices}
          text={t("sections.multipleDevices.title")}
          section={`#${SECTIONS.DEVICES}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={translate}
          text={t("sections.languages.title")}
          section={`#${SECTIONS.LANGUAGES}`}
        />
      </Grid>
    </Grid>
  );
};

export default FeaturesList;
