import { createTheme } from "@mui/material";
export const THEME = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgb(109, 94, 0)",
    },
    secondary: {
      main: "rgb(255, 226, 71)",
    },
    error: {
      main: "rgb(186, 26, 26)",
    },
    warning: {
      main: "rgb(237, 227, 188)",
    },
    success: {
      main: "rgb(31, 109, 0)",
    },
    info: {
      main: "rgba(101,94,64,0.82)",
    },
    black: {
      main: "#000",
    },
    background: {
      default: "#ffffff",
    },
  },
});

export const GENERAL = {
  EMPTY_STRING: "",
  MAX_MOBILE_WIDTH: 600,
};

export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/*",
  APP_TERMS_OF_USE: "/terms-of-use",
  APP_PRIVACY_POLICY: "/privacy-policy",
  APP_END_USER_LICENSE_AGREEMENT: "/eula",
  ADMIN: "/admin",
};

export const SERVER_ROUTES = {
  BASE: "https://superdupershoppinglist-be-v1.onrender.com/api",
  //https://superdupershoppinglist-be-v1.onrender.com/api
  //http://localhost:5000/api
  USER: {
    LOG_IN: "/user/log-in",
    ALL: "/user/admin/all",
  },
};

export const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  FAIL: "fail",
  CANCEL: "cancel",
};

export const LINKS = {
  INSTAGRAM: "https://www.instagram.com/superduper.shoppinglist",
  FACEBOOK: "https://www.facebook.com/profile.php?id=61550643921350",
  EMAIL: "superduper.shoppinglist@gmail.com",
  WEBSITE: "https://www.superdupershoppinglist.com",
  MEDIA: "@superduper.shoppinglist",
  APP_STORE: "https://apps.apple.com/app/superduper-shopping-list/id6463705245",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.chaimagal.superduper",
};

export const SECTIONS = {
  LANGUAGES: "languages",
  DEVICES: "devices",
  UNLIMITED: "unlimited",
  SHARE: "share",
  ITEMS: "items",
  EXPORT: "export",
};

export const LANGUAGES = {
  English: {
    CODE: "en",
    VALUE: "English",
  },
  Hebrew: {
    CODE: "he",
    VALUE: "עברית",
  },
  Arabic: {
    CODE: "ar",
    VALUE: "العربية",
  },
  Catalan: {
    CODE: "ca",
    VALUE: "Català",
  },
  Chinese: {
    CODE: "zh",
    VALUE: "中国人",
  },
  Croatian: {
    CODE: "hr",
    VALUE: "Hrvatski",
  },
  Czech: {
    CODE: "cs",
    VALUE: "čeština",
  },
  Danish: {
    CODE: "da",
    VALUE: "dansk",
  },
  Finnish: {
    CODE: "fi",
    VALUE: "Suomalainen",
  },
  French: {
    CODE: "fr",
    VALUE: "Français",
  },
  German: {
    CODE: "de",
    VALUE: "Deutsch",
  },
  Greek: {
    CODE: "el",
    VALUE: "Ελληνικά",
  },
  Hindi: {
    CODE: "hi",
    VALUE: "हिंदी",
  },
  Hungarian: {
    CODE: "hu",
    VALUE: "Magyar",
  },
  Indonesian: {
    CODE: "id",
    VALUE: "bahasa Indonesia",
  },
  Italian: {
    CODE: "it",
    VALUE: "Italiano",
  },
  Japanese: {
    CODE: "ja",
    VALUE: "日本",
  },
  Korean: {
    CODE: "ko",
    VALUE: "한국인",
  },
  Malay: {
    CODE: "ms",
    VALUE: "Melayu",
  },
  Norwegian: {
    CODE: "nb",
    VALUE: "norsk",
  },
  Polish: {
    CODE: "pl",
    VALUE: "Polski",
  },
  Portuguese: {
    CODE: "pt",
    VALUE: "Português",
  },
  Romanian: {
    CODE: "ro",
    VALUE: "Română",
  },
  Russian: {
    CODE: "ru",
    VALUE: "Русский",
  },
  Slovak: {
    CODE: "sk",
    VALUE: "slovenský",
  },
  Spanish: {
    CODE: "es",
    VALUE: "español",
  },
  Swedish: {
    CODE: "sv",
    VALUE: "svenska",
  },
  Thai: {
    CODE: "th",
    VALUE: "ภาษาไทย",
  },
  Turkish: {
    CODE: "tr",
    VALUE: "Türk",
  },
  Ukrainian: {
    CODE: "uk",
    VALUE: "українська",
  },
  Vietnamese: {
    CODE: "vi",
    VALUE: "Tiếng Việt",
  },
};
