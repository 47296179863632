import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  Toolbar,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import logo from "../assets/logo.png";
import { ROUTES } from "../utils/constants";
import { useTranslation } from "react-i18next";
import { Squash as Hamburger } from "hamburger-react";
import { useNavigate } from "react-router";
import AgreementButton from "./common/topNav/AgreementButton";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const TopNav = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);

  const handleNavMenu = () => {
    setAnchorElNav(!anchorElNav);
    setBurgerNav(!burgerNav);
  };

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar style={{ backgroundColor: theme.palette.background.default }}>
          <Container sx={{ px: 0 }}>
            <Toolbar sx={{ px: 1 }}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(ROUTES.HOME);
                }}
                style={{
                  textTransform: "capitalize",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar alt="logo" src={logo} />
                <Box width={5} />
                {t("helmet.home")}
              </Button>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <AgreementButton
                  text={t("app.termsOfUse.title")}
                  route={ROUTES.APP_TERMS_OF_USE}
                />
                <AgreementButton
                  text={t("app.privacyPolicy.title")}
                  route={ROUTES.APP_PRIVACY_POLICY}
                />
                <AgreementButton
                  text={t("app.endUserLicenseAgreement.title")}
                  route={ROUTES.APP_END_USER_LICENSE_AGREEMENT}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: theme.palette.primary.main,
                }}
              >
                <Hamburger
                  toggled={burgerNav}
                  toggle={handleNavMenu}
                  size={20}
                  duration={0.2}
                ></Hamburger>
              </Box>
            </Toolbar>
            <Collapse in={anchorElNav} timeout="auto" unmountOnExit>
              <List>
                <ListItem sx={{ justifyContent: "center" }}>
                  <AgreementButton
                    text={t("app.termsOfUse.title")}
                    route={ROUTES.APP_TERMS_OF_USE}
                  />
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <AgreementButton
                    text={t("app.privacyPolicy.title")}
                    route={ROUTES.APP_PRIVACY_POLICY}
                  />
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <AgreementButton
                    text={t("app.endUserLicenseAgreement.title")}
                    route={ROUTES.APP_END_USER_LICENSE_AGREEMENT}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default TopNav;
