import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Facebook, Instagram, MailOutline } from "@mui/icons-material";
import HeightSpacer from "../HeightSpacer";
import { LINKS } from "../../../utils/constants";

const SocialMedia = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <HeightSpacer height={16} />
      <Button
        variant="text"
        color="secondary"
        sx={{ textTransform: "none" }}
        onClick={() => {
          window.open(`mailto:${LINKS.EMAIL}`, "_self");
        }}
      >
        <MailOutline color="secondary" sx={{ mx: 1 }} />
        {t("footer.email")}
      </Button>
      <HeightSpacer height={16} />
      <Box>
        <Button
          variant="text"
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={() => {
            window.open(LINKS.FACEBOOK);
          }}
        >
          <Facebook color="inherit" fontSize="large" />
        </Button>
        <Button
          variant="text"
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={() => {
            window.open(LINKS.INSTAGRAM);
          }}
        >
          <Instagram color="inherit" fontSize="large" />
        </Button>
      </Box>
      <HeightSpacer height={16} />
      <Typography variant="body1">{LINKS.MEDIA}</Typography>
      <HeightSpacer height={16} />
    </Box>
  );
};

export default SocialMedia;
