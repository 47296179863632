import React from "react";
import BlankPage from "../BlankPage";
import { Grid } from "@mui/material";
import TitleAndStoreLinks from "./TitleAndStoreLinks";
import FeaturesList from "./FeaturesList";

const Header = () => {
  return (
    <BlankPage>
      <Grid container spacing={5} py={10}>
        <TitleAndStoreLinks />
        <FeaturesList />
      </Grid>
    </BlankPage>
  );
};

export default Header;
