import React from "react";
import { useSelector } from "react-redux";
import BlankPage from "../components/BlankPage";
import AdminLogin from "../components/Admin/AdminLogin";
import AdminDashboard from "../components/Admin/AdminDashboard";

const Admin = () => {
  const { token } = useSelector((state) => state.user);
  return <BlankPage>{token ? <AdminDashboard /> : <AdminLogin />}</BlankPage>;
};

export default Admin;
