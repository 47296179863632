import React from "react";
import BlankPage from "../BlankPage";
import { Typography } from "@mui/material";
import HeightSpacer from "./HeightSpacer";

const Agreement = ({ title, content }) => {
  return (
    <BlankPage>
      <HeightSpacer />
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {content}
      </Typography>
    </BlankPage>
  );
};

export default Agreement;
