import React from "react";
import BlankPage from "../BlankPage";
import { useTranslation } from "react-i18next";
import Section from "../common/Section";
import { SECTIONS } from "../../utils/constants";

//gifs
import devices from "../../assets/gif/devices.gif";
import unlimited from "../../assets/gif/unlimited.gif";
import network from "../../assets/gif/network.gif";
import list from "../../assets/gif/list.gif";
import exportGIF from "../../assets/gif/export.gif";
import translate from "../../assets/gif/translate.gif";

//screenshots
import settingsScreen from "../../assets/screenshot/settings-screen.png";
import exportScreen from "../../assets/screenshot/export-screen.png";
import itemsScreen from "../../assets/screenshot/items-screen.png";
import languageScreen from "../../assets/screenshot/language-screen.png";
import listsScreen from "../../assets/screenshot/lists-screen.png";
import shareScreen from "../../assets/screenshot/share-screen.png";

const Sections = () => {
  const { t } = useTranslation();
  return (
    <BlankPage>
      <Section
        id={SECTIONS.UNLIMITED}
        gif={unlimited}
        image={listsScreen}
        title={t("sections.unlimitedLists.title")}
        text={t("sections.unlimitedLists.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.SHARE}
        gif={network}
        image={shareScreen}
        title={t("sections.shareLists.title")}
        text={t("sections.shareLists.text")}
      />
      <Section
        id={SECTIONS.ITEMS}
        gif={list}
        image={itemsScreen}
        title={t("sections.customizeItems.title")}
        text={t("sections.customizeItems.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.EXPORT}
        gif={exportGIF}
        image={exportScreen}
        title={t("sections.exportText.title")}
        text={t("sections.exportText.text")}
      />
      <Section
        id={SECTIONS.DEVICES}
        gif={devices}
        image={settingsScreen}
        title={t("sections.multipleDevices.title")}
        text={t("sections.multipleDevices.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.LANGUAGES}
        gif={translate}
        image={languageScreen}
        title={t("sections.languages.title")}
        text={t("sections.languages.text")}
      />
    </BlankPage>
  );
};

export default Sections;
