import React from "react";
import { Box, Typography } from "@mui/material";

import logo from "../../../assets/logo.png";
import { useTranslation } from "react-i18next";

const LogoAndContact = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        component="img"
        src={logo}
        alt="logo"
        sx={{ maxWidth: 150, borderRadius: 5 }}
      />
      <Typography variant="body1">{t("footer.logoText")}</Typography>
    </Box>
  );
};

export default LogoAndContact;
