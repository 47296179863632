import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import BlankPage from "../components/BlankPage";
import { Box } from "@mui/material";

import notfound from "../assets/gif/not-found.gif";

const NotFound = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("helmet.notFound")}</title>
      </Helmet>
      <BlankPage>
        <Box
          component={"img"}
          src={notfound}
          alt={"notfound"}
          width={"100%"}
          maxWidth={500}
          borderRadius={500}
        />
      </BlankPage>
    </>
  );
};

export default NotFound;
