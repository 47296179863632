import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sections from "../components/home/Sections";
import Header from "../components/home/Header";
import YoutubeVideo from "../components/home/YoutubeVideo";

const Home = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <Header />
      <YoutubeVideo />
      <Sections />
    </>
  );
};

export default Home;
