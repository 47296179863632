import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeightSpacer from "../common/HeightSpacer";

const YoutubeVideo = () => {
  const { height } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "sticky", top: 100 }}>
        <Typography
          sx={{ typography: { xs: "h4", md: "h2" }, textAlign: "center" }}
        >
          {t("youtube.title")}
        </Typography>
        <HeightSpacer height={30} />
        <iframe
          width="100%"
          height={height / 2}
          src="https://www.youtube.com/embed/nygfrXdhme0?si=zYBs84dAdnUajC70"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{ borderRadius: 16, boxShadow: "none", border: "none" }}
        ></iframe>
      </Box>
    </Container>
  );
};

export default YoutubeVideo;
