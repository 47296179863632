import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const AgreementButton = ({ text, route }) => {
  const navigate = useNavigate();

  return (
    <Button
      style={{ textTransform: "capitalize" }}
      fullWidth
      sx={{ minWidth: 150 }}
      onClick={() => {
        navigate(route);
      }}
    >
      {text}
    </Button>
  );
};

export default AgreementButton;
