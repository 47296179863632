import React from "react";
import { Box, Container, Typography } from "@mui/material";
import LogoAndContact from "./common/footer/LogoAndContact";
import SocialMedia from "./common/footer/SocialMedia";
import { useTranslation } from "react-i18next";
import HeightSpacer from "./common/HeightSpacer";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-evenly",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          // minHeight: "50vh",
          backgroundColor: "#282828",
          color: "#fff",
          margin: 0,
        }}
      >
        <Container>
          <Box
            sx={{
              justifyContent: "space-evenly",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              paddingY: 8,
            }}
          >
            <LogoAndContact />
            <SocialMedia />
          </Box>
        </Container>

        <Typography variant="body1">{t("footer.allRightsReserved")}</Typography>
        <HeightSpacer height={16} />
      </Box>
    </>
  );
};

export default Footer;
