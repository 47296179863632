import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, TextField } from "@mui/material";
import HeightSpacer from "../common/HeightSpacer";
import { STATUS } from "../../utils/constants";
import { logIn } from "../../redux/user/userSlice";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const { logIn: loginStatus } = useSelector((state) => state.user.status);

  const handleSubmit = (e) => {
    e?.preventDefault();
    const data = {
      email: e?.target?.email?.value,
      password: e?.target?.password?.value,
    };
    dispatch(logIn(data));
  };
  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: 400 }}>
        <TextField name={"email"} label={"email"} type="email" fullWidth />
        <HeightSpacer height={50} />
        <TextField
          name={"password"}
          label={"password"}
          type="password"
          fullWidth
        />
        <HeightSpacer height={50} />
        <Button type={"submit"} fullWidth>
          {loginStatus === STATUS.LOADING ? <CircularProgress /> : "submit"}
        </Button>
      </form>
    </>
  );
};

export default AdminLogin;
