import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { GENERAL, SERVER_ROUTES, STATUS } from "../../utils/constants";

//@POST - Log In
export const logIn = createAsyncThunk(
  SERVER_ROUTES.BASE + SERVER_ROUTES.USER.LOG_IN,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(
        SERVER_ROUTES.BASE + SERVER_ROUTES.USER.LOG_IN,
        data, //email, password
        {
          headers: {
            // "Accept-Language": languageCode,
          },
        }
      );
    } catch (e) {
      alert(e?.response?.data?.message || e?.message);
      return rejectWithValue(e?.response?.data?.message || e?.message);
    }
  }
);

//@GET - All Users Admin
export const getAllUsers = createAsyncThunk(
  SERVER_ROUTES.BASE + SERVER_ROUTES.USER.GET_ALL,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.get(SERVER_ROUTES.BASE + SERVER_ROUTES.USER.ALL, {
        headers: {
          // "Accept-Language": languageCode,
          Authorization: `Bearer ${data}`,
        },
      });
    } catch (e) {
      alert(e?.response?.data?.message || e?.message);
      return rejectWithValue(e?.response?.data?.message || e?.message);
    }
  }
);

const initialState = {
  token: GENERAL.EMPTY_STRING,
  allUsers: GENERAL.EMPTY_STRING,
  status: {
    logIn: GENERAL.EMPTY_STRING,
    getAllUsers: GENERAL.EMPTY_STRING,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Log In
    builder.addCase(logIn.pending, (state) => {
      state.status.logIn = STATUS.LOADING;
    });
    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.status.logIn = STATUS.SUCCESS;
      state.token = payload.data.token;
    });
    builder.addCase(logIn.rejected, (state) => {
      state.status.logIn = STATUS.FAIL;
      state.token = GENERAL.EMPTY_STRING;
    });
    //GET All Users
    builder.addCase(getAllUsers.pending, (state) => {
      state.status.getAllUsers = STATUS.LOADING;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.status.getAllUsers = STATUS.SUCCESS;
      state.allUsers = payload.data;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.token = GENERAL.EMPTY_STRING;
      state.allUsers = GENERAL.EMPTY_STRING;
      state.status.getAllUsers = STATUS.FAIL;
    });
  },
});

// export const {  } =
//   userSlice.actions;

export default userSlice.reducer;
